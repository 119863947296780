export const MANUAL_SUBSTEP_DATA = [
    {
        id: '1',
        manualId: 'manual_1234',
        stepId: '1',
        dOrder: 1,
        _key: 'MANUAL_SUBSTEP',
        title: 'Overview',
        referenceMedia: [],
        content: [
            {
                id: '1',
                dOrder: 1,
                type: 'html',
                content: '<p>The Vector System requires an additional fuel compartment, which was added in the form of a right hand (Ditch Side) tank with 97 gallons of Biodiesel capacity.</p>'
            },
            {
                id: '2',
                dOrder: 2,
                type: 'media',
                url: 'https://via.placeholder.com/150'
            },
            {
                id: '3',
                dOrder: 3,
                type: 'html',
                content: '<p>General Guidelines<ul><li>Be cautious of flammable liquids during removal and installation of fuel tanks</li><li>Defer to the manufacturer\'s guidelines on drilling into the vehicle frame when installing additional tank brackets.</li></ul></p><p><b>Replacing an Existing Tank</b><ul><li>Follow manufacturer\'s guidelines for OEM tank removal, use the following steps as a guide</li></ul></p>'
            }
        ]
    },
    {
        id: '2',
        manualId: 'manual_1234',
        stepId: '1',
        dOrder: 2,
        _key: 'MANUAL_SUBSTEP',
        title: 'Removing the OEM Tank',
        referenceMedia: [
            {
                id: '2',
                dOrder: 1,
                type: 'image',
                url: 'https://via.placeholder.com/150'
            }
        ],
        content: [
            {
                id: '4',
                dOrder: 1,
                type: 'html',
                content: '<ol><li>Identify the Ditch side, 100 gallon OEM tank. Drain all fuel from the OEM tank.</li><li>Turn the qty-2 ball valves located on the rear of the OEM tank to the OFF position. While the ball valves are still connected to the supply and return fuel lines, remove the ball valves from the OEM tank and set them aside. Ensure the valves are still connected to the fuel lines and that no leaking is taking place.</li><li>Disconnect he tank vent line and fuel levels ender connector(if applicable) and set aside for future use.</li><li>Support the weight of the fuel tank in anticipation of loosening the tank straps.</li><li>Loosen tank straps to the brackets.</li><li>Fully disconnect the tank and remove it from the brackets. Set aside and out of the installation area.</li></ol>'
            },
            {
                id: '5',
                dOrder: 2,
                type: 'action',
                actionId: 'act_1234'
            }
        ]
    },
    {
        id: '3',
        manualId: 'manual_1234',
        stepId: '1',
        dOrder: 3,
        _key: 'MANUAL_SUBSTEP',
        title: 'Mounting the Vector B100 Tank',
        referenceMedia: [],
        content: [
            {
                id: '6',
                dOrder: 1,
                type: 'html',
                content: '<ol><li>TBD: Copy in the steps...</li></ol>'
            }
        ]
    }
]
