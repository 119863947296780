export const MANUAL_STEP_DATA = [
    {
        id: '1',
        manualId: 'manual_1234',
        groupId: '1',
        dOrder: 1,
        _key: 'MANUAL_STEP',
        title: 'Swap OEM Tank for Vector Fuel Tank'
    },
    {
        id: '2',
        manualId: 'manual_1234',
        groupId: '1',
        dOrder: 2,
        _key: 'MANUAL_STEP',
        title: 'Manifold Bracket Mounting'
    },
    {
        id: '3',
        manualId: 'manual_1234',
        groupId: '1',
        dOrder: 3,
        _key: 'MANUAL_STEP',
        title: 'Manifold, Control Unit, and PDM Mounting'
    }
]
