import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { nestedRoutePath, routes } from '../../../constants/routes'

// import BuilderSidebar from './builder-sidebar'
import ManualHeader from './builder-header'
import BuilderPage from './builder-page'
// import ManualStep from './builder-step'
// import ManualSubstep from './builder-substep'
// import BuilderSidebarSteps from './builder-sidebar-steps'
// import BuilderSidebarSubsteps from './builder-sidebar-substeps'

// const mapStateToProps = (state, ownProps) => {
//     return {
//         manual: state.manuals.find(manual => manual.id === ownProps.params.manualId && manual.version === Number(ownProps.params.versionId)),
//         manualId: ownProps.params.manualId
//     }
// }

const AdminManualBuilderIndex = () => {
    return (
        <>
            <div className="builder-view">
                <ManualHeader />
                <Routes>
                    <Route path={nestedRoutePath(routes.adminManualBuilderIndex.path, routes.adminManualBuilderSubstep.path)} element={<BuilderPage />} />
                    <Route path={nestedRoutePath(routes.adminManualBuilderIndex.path, routes.adminManualBuilderStep.path)} element={<BuilderPage />} />
                    <Route index element={<BuilderPage />} />
                </Routes>
            </div>
        </>
    )
}

export default withRouterProps(connect()(AdminManualBuilderIndex))
