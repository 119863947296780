import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouterProps } from '../../utils/with-router-props'
import { v4 as uuidv4 } from 'uuid'

import { buildRoutePath, routes } from '../../../constants/routes'
import { fakeActionCall } from '../../../actions/api'
import { Link } from 'react-router-dom'
import EditableDisplayField from '../../utils/editable-display-field'

const selectManualId = (state, manualId) => manualId
const selectGroups = state => state.manualGroups
const selectSteps = state => state.manualSteps
const selectSubsteps = state => state.manualSubsteps

const makeGroups = createSelector(
    [selectManualId, selectGroups],
    (manualId, groups) => {
        return groups.filter(group => group.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const makeSteps = createSelector(
    [selectManualId, selectSteps],
    (manualId, steps) => {
        return steps.filter(step => step.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const makeSubsteps = createSelector(
    [selectManualId, selectSubsteps],
    (manualId, substeps) => {
        return substeps.filter(substep => substep.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const mapStateToProps = (state, ownProps) => {
    const manualId = ownProps.params.manualId
    const versionId = ownProps.params.versionId
    // TODO: How to get the version steps to match the manual version?
    return {
        activeStep: state.manualSteps.find(step => step.id === ownProps.params.stepId),
        activeSubstep: state.manualSubsteps.find(sub => sub.id === ownProps.params.substepId),
        manualId: manualId,
        manual: state.manuals.find(manual => manual.id === manualId && manual.version === Number(versionId)),
        manualGroups: makeGroups(state, manualId),
        manualSteps: makeSteps(state, manualId),
        manualSubsteps: makeSubsteps(state, manualId)
    }
}

const AdminManualBuilderPage = ({ dispatch, activeStep, activeSubstep, manualId, manual, manualGroups, manualSteps, manualSubsteps }) => {
    const addManualGroup = () => {
        let newGroup = {
            id: uuidv4(),
            manualId: manualId,
            dOrder: manualGroups.length + 1,
            _key: 'MANUAL_GROUP',
            title: 'New Group: Untitled'
        }
        dispatch(fakeActionCall('MANUAL_GROUPS/singleAction', newGroup))
    }

    const addManualStep = (parentId) => {
        let newStep = {
            id: uuidv4(),
            manualId: manualId,
            groupId: parentId,
            dOrder: manualSteps.length + 1,
            _key: 'MANUAL_STEP',
            title: 'New Step: Untitled'
        }
        dispatch(fakeActionCall('MANUAL_STEPS/singleAction', newStep))
    }

    const addManualSubstep = (stepId) => {
        let newSubtep = {
            id: uuidv4(),
            manualId: manualId,
            stepId: stepId,
            dOrder: manualSubsteps.length + 1,
            _key: 'MANUAL_SUBSTEP',
            title: 'New Substep: Untitled'
        }
        dispatch(fakeActionCall('MANUAL_SUBSTEPS/singleAction', newSubtep))
    }

    const renderSidebarGroups = () => {
        let groups = manualGroups.map(group => {
            return (
                <div key={group.id} className="manual-group-wrapper">
                    <div className="group-wrapper">
                        <EditableDisplayField
                            extraClass="inline-edit group-label"
                            display={group.title}
                            endpoint="to-do"
                            dataObj={group}
                            fields={[{
                                field: 'title',
                                type: 'text',
                                required: true
                            }]} />
                        { renderSidebarSteps(group.id) }
                    </div>
                </div>
            )
        })
        return (
            <>
                { groups }
                <div className="btn small" onClick={addManualGroup}>
                    Add Group
                </div>
            </>
        )
    }

    const renderSidebarSteps = (groupId) => {
        let steps = manualSteps.filter(step => step.groupId === groupId).map(step => {
            return (
                <Link
                    key={step.id}
                    className={`step-item ${step.id === activeStep?.id ? 'active' : ''}`}
                    to={
                        buildRoutePath(routes.adminManualBuilderStep.path, {
                            manualId: manual.id,
                            versionId: manual.version,
                            stepId: step.id })}>
                    <div className="step-label">
                        { step.title }
                    </div>
                </Link>
            )
        })
        return (
            <>
                { steps }
                <div className="btn small dashed" onClick={() => addManualStep(groupId)} >
                    Add Step
                </div>
            </>
        )
    }

    const renderSidebarSubsteps = () => {
        if (!manualSubsteps || !activeStep) {
            return null
        }
        let subSteps = manualSubsteps.filter(substep => substep.stepId === activeStep.id).map(substep => {
            return (
                <Link
                    key={substep.id}
                    className={`step-item ${substep.id === activeSubstep?.id ? 'active' : ''}`}
                    to={
                        buildRoutePath(routes.adminManualBuilderSubstep.path, {
                            manualId: manual.id,
                            versionId: manual.version,
                            stepId: activeStep.id,
                            substepId: substep.id })}>
                    <div className="step-label">
                        { substep.title }
                    </div>
                </Link>
            )
        })
        return (
            <div className="step-list-wrapper">
                <div className="step-list">
                    {subSteps}
                    <div className="btn small dashed" onClick={() => addManualSubstep(activeStep.id)} >
                        Add Substep
                    </div>
                </div>
            </div>
        )
    }

    if (!manual) {
        return <>loading</>
    }

    return (
        <div className="content-wrapper">
            {/* Begin the builder steps */}
            <div className="builder-sidebar builder-steps">
                { renderSidebarGroups() }
                <hr />
                <pre>
                    Steps can be drag / dropped to reorder<br/>
                    TBD: Can I move steps from group to group?<br/>
                </pre>
            </div>
            <div className="builder-content">
                {/* Begin builder steps  */}
                <div className="builder-step-header">
                    {activeStep && 
                        <EditableDisplayField
                            extraClass="inline-edit"
                            display={activeStep.title}
                            endpoint="to-do"
                            dataObj={activeStep}
                            fields={[{
                                field: 'title',
                                type: 'text',
                                required: true
                            }]} />
                    }
                    {!activeStep && <div>No Step Selected</div>}
                </div>
                <div className="content-wrapper">
                    <div className="builder-sidebar builder-substeps">
                        { renderSidebarSubsteps() }
                        <hr />
                        <pre>
                            Print list of Substeps.<br/>
                            Substeps can be drag / dropped to reorder<br/>
                        </pre>
                    </div>
                    <div className="builder-content">
                        { activeSubstep && (
                            <EditableDisplayField
                                // label="Title"
                                extraClass="inline-edit"
                                // inTable={true}
                                display={activeSubstep.title}
                                endpoint="to-do"
                                dataObj={activeSubstep}
                                fields={[{
                                    field: 'title',
                                    type: 'text',
                                    required: true
                                }]} />
                        )}
                        <pre>
                            Print the substep content as an editable "blocks"<br/>
                            Print dropdown to add elements to the substep<br/>
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminManualBuilderPage))
