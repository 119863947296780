import React from 'react'
import { withRouterProps } from '../../utils/with-router-props'
import { connect } from 'react-redux'
// import { buildRoutePath, routes } from '../../../constants/routes'
// import { Link } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
    return {
        manual: state.manuals.find(manual => manual.id === ownProps.params.manualId && manual.version === Number(ownProps.params.versionId))
    }
}

const ManualHeader = ({ manual }) => {
    
    if (!manual) {
        return (
            <div>
                Loading the manual...
            </div>
        )
    }

    return (
        <div className="manual-header">
            <h1>{ manual.title }</h1>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(ManualHeader))
