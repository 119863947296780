
export const MANUAL_GROUP_DATA = [
    {
        id: '1',
        title: 'Group 1',
        manualId: 'manual_1234',
        phase: 1,
        dOrder: 1,
        _key: 'MANUAL_GROUP'
    },
    {
        id: '2',
        title: 'Group 2',
        manualId: 'manual_1234',
        phase: 1,
        dOrder: 2,
        _key: 'MANUAL_GROUP'
    }
]
