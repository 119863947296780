import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CreateManual = () => {
    return (
        <div className="content">
            <div className="page-title">
                <Link to={routes.adminManualsIndex.path} className="icon-link">
                    <FontAwesomeIcon icon="circle-left" />
                </Link>
                <h1>Create Manual</h1>
            </div>
            <div className="manual-list">
                form element here
            </div>
        </div>
    )
}

export default CreateManual
