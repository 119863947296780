import apiCacheReducer from './api-cache'
import authReducer from './auth'
import authCodeReducer from './auth-code'
import formReducer from './form'
import genericSlice from './generic-slice'
import pagerReducer from './pager'
import uiReducer from './ui'

import { INITIAL_TRUCK_DATA, INITIAL_MANUAL_DATA } from '../constants/initial-reducer-data'

// Breaking manuals down into their pieces
import { MANUAL_DATA } from '../constants/manual-data'
import { MANUAL_GROUP_DATA } from '../constants/manual-group-data'
import { MANUAL_STEP_DATA } from '../constants/manual-step-data'
import { MANUAL_SUBSTEP_DATA } from '../constants/manual-substep-data'

const indexReducer = {
    account: genericSlice('ACCOUNT', {}),
    adminUser: genericSlice('ADMIN_USER'),
    adminRoles: genericSlice('ADMIN_ROLE'),
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    configs: genericSlice('CONFIGS'),
    forms: formReducer,
    manualsOld: genericSlice('MANUALS_OLD', INITIAL_MANUAL_DATA),
    manuals: genericSlice('MANUALS', MANUAL_DATA),
    manualGroups: genericSlice('MANUAL_GROUPS', MANUAL_GROUP_DATA),
    manualSteps: genericSlice('MANUAL_STEPS', MANUAL_STEP_DATA),
    manualSubsteps: genericSlice('MANUAL_SUBSTEPS', MANUAL_SUBSTEP_DATA),
    paginator: pagerReducer,
    trucks: genericSlice('TRUCKS', INITIAL_TRUCK_DATA),
    roles: genericSlice('ROLES'),
    teams: genericSlice('TEAMS'),
    ui: uiReducer,
    user: genericSlice('USER', {})
}

export default indexReducer
