import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { buildRoutePath, routes } from '../../../constants/routes'

const mapStateToProps = (state) => {
    return {
        manuals: state.manuals
    }
}

const ListManuals = ({ manuals }) => {
    const renderManuals = () => {
        let allManuals = []
        manuals.forEach(manual => {
            allManuals.push(
                <div key={manual.id} className="manual-list-item">
                    <div className="manual-list-item-title">
                        <h3>{manual.title}: {manual.version}</h3>
                        <div>
                            <Link to={buildRoutePath(routes.adminManualsView.path, { manualId: manual.id, versionId: manual.version })} className="btn">
                                View
                            </Link>
                            <Link to={buildRoutePath(routes.adminManualBuilderIndex.path, { manualId: manual.id, versionId: manual.version })} className="btn">
                                Build
                            </Link>
                        </div>
                    </div>
                </div>
            )
        })
        return (
            <div className="manual-list">
                {allManuals}
            </div>
        )
    }

    return (
        <div className="content">
            <div className="page-title">
                <h1>Manuals</h1>
            </div>
            {renderManuals()}
            <div className="footer-btns">
                <Link to={routes.adminManualsCreate.path} className="btn blue main">
                    Create New Manual
                </Link>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(ListManuals)
